import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Products from './views/Products.vue';
import Denied from './views/Denied.vue';
import Unknown from './views/Unknown.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'emstone-hub',
      component: Home,
      meta: {
        icon: 'mdi-home',
        ignoreAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ './views/Login.vue'),
      meta: {
        ignoreAuth: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () =>
        import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
    },
    {
      path: '/projects',
      name: 'projects',
      component: () =>
        import(/* webpackChunkName: "projects" */ './views/Projects.vue'),
      meta: {
        icon: 'mdi-shield-lock',
        permission: 'project',
        home: true,
      },
    },
    {
      path: '/projects/:id',
      name: 'project',
      component: () =>
        import(/* webpackChunkName: "projects" */ './views/Project.vue'),
      meta: {
        parent: 'projects',
        permission: 'project',
      },
    },
    {
      path: '/services',
      name: 'services',
      component: () =>
        import(/* webpackChunkName: "services" */ './views/Services.vue'),
      meta: {
        icon: 'mdi-hospital-box',
        permission: 'service',
        home: true,
      },
    },
    {
      path: '/services/:id',
      name: 'service',
      component: () =>
        import(/* webpackChunkName: "services" */ './views/Service.vue'),
      meta: {
        parent: 'services',
        permission: 'service',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () =>
        import(/* webpackChunkName: "orders" */ './views/Orders.vue'),
      meta: {
        icon: 'mdi-thumb-up',
        permission: 'order',
        home: true,
      },
    },
    {
      path: '/orders/:id',
      name: 'order',
      component: () =>
        import(/* webpackChunkName: "orders" */ './views/Order.vue'),
      meta: {
        parent: 'orders',
        permission: 'order',
      },
    },

    {
      path: '/estimates',
      name: 'estimates',
      component: () =>
        import(/* webpackChunkName: "estimates" */ './views/Estimates.vue'),
      meta: {
        icon: 'mdi-alarm-panel',
        permission: 'estimate',
        home: true,
      },
    },
    {
      path: '/estimates/:id',
      name: 'estimate',
      component: () =>
        import(/* webpackChunkName: "estimates" */ './views/Estimate.vue'),
      meta: {
        parent: 'estimates',
        permission: 'estimate',
      },
    },
    {
      path: '/agreements',
      name: 'agreements',
      component: () =>
        import(/* webpackChunkName: "agreements" */ './views/Agreements.vue'),
      meta: {
        icon: 'mdi-file-plus',
        permission: 'agreement',
        home: true,
      },
    },
    {
      path: '/agreements/:id',
      name: 'agreement',
      component: () =>
        import(/* webpackChunkName: "agreements" */ './views/Agreement.vue'),
      meta: {
        parent: 'agreements',
        permission: 'agreement',
      },
    },
    {
      path: '/issues',
      name: 'issues',
      component: () =>
        import(/* webpackChunkName: "issues" */ './views/Issues.vue'),
      meta: {
        icon: 'mdi-bug',
        permission: 'issue',
        home: true,
      },
    },
    {
      path: '/issues/:id',
      name: 'issue',
      component: () =>
        import(/* webpackChunkName: "issues" */ './views/Issue.vue'),
      meta: {
        parent: 'issues',
        permission: 'issue',
      },
    },
    {
      path: '/licenses',
      name: 'licenses',
      component: () =>
        import(/* webpackChunkName: "licenses" */ './views/Licenses.vue'),
      meta: {
        icon: 'mdi-certificate-outline',
        permission: 'license',
        home: true,
      },
    },
    {
      path: '/licenses/issue',
      name: 'license-issue',
      component: () =>
        import(/* webpackChunkName: "licenses" */ './views/LicenseIssue.vue'),
      meta: {
        parent: 'licenses',
        permission: 'license-edit',
      },
    },
    {
      path: '/licenses/:id',
      name: 'license',
      component: () =>
        import(/* webpackChunkName: "licenses" */ './views/License.vue'),
      meta: {
        parent: 'licenses',
        permission: 'license',
      },
    },
    {
      path: '/products',
      name: 'products',
      component: Products,
      meta: {
        icon: 'mdi-gift',
        permission: 'product',
        home: true,
      },
    },
    {
      path: '/edns',
      name: 'edns',
      component: () =>
        import(/* webpackChunkName: "edns" */ './views/Edns.vue'),
      meta: {
        icon: 'mdi-dns',
        permission: 'edns',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () =>
        import(/* webpackChunkName: "users" */ './views/Users.vue'),
      meta: {
        icon: 'mdi-account-multiple',
        permission: 'user',
      },
    },
    {
      path: '/vendors',
      name: 'vendors',
      component: () =>
        import(/* webpackChunkName: "vendors" */ './views/Vendors.vue'),
      meta: {
        icon: 'mdi-domain',
        permission: 'vendor',
      },
    },
    {
      path: '/customers',
      name: 'customers',
      component: () =>
        import(/* webpackChunkName: "customers" */ './views/Customers.vue'),
      meta: {
        icon: 'mdi-bank',
        permission: 'vendor',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () =>
        import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
      meta: {
        icon: 'mdi-cog',
        permission: 'setting',
      },
    },
    {
      path: '/denied',
      name: 'Permission Denied',
      component: Denied,
    },
    {
      path: '*',
      name: 'Page Not Found',
      component: Unknown,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
export default router;

if (global) global.$router = router;
